import { orderBy, partition } from 'lodash';
import { Component, OnInit, OnDestroy, HostBinding, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Subject, catchError, combineLatest, of, switchMap, takeUntil, throwError } from 'rxjs';
import { BreakpointService } from '../services/breakpoint.service';
import { PatientService } from '../services/patient.service';
import { FhirPatientResponse } from '../shared/models/fhir/patient/response/fhir-patient-response';
import { showSidebar$ } from '@bayshoreHealthCare/store';
import { CustomBreakpointState } from '../shared/interfaces/custom-breakpoint-state-interface';
import { CarePlanService } from '../services/careplan.service';
import { CarePlan, PlanDefinition } from 'fhir/r4';
import { MapBundleToResourceArray } from '../mappers/shared.mapper';
import { CalcomService } from '../services/calcom.service';
import { DefaultDialogProperties } from '../config/app.config';
import { CalcomEvent, CalcomEventTypes, SelectCalcomTeamMemberModalData } from "../models/calcom.model";
import { MatDialog } from '@angular/material/dialog';
import { SelectCalcomTeamMemberComponent } from '../components/select-calcom-team-member/select-calcom-team-member.component';
import { ShowLoaderService } from '../services/show-loader.service';
import { NotificationService } from '../services/notification.service';
import { ActionForProgram } from '../models/calcom.model';

@Component({
  selector: 'patient-app-patient-overview',
  templateUrl: './patient-overview.component.html',
  styleUrls: ['./patient-overview.component.scss']
})
export class PatientOverviewComponent implements OnInit, OnDestroy {
  navLinks: Array<any> = [];

  // hide it for demo
  //navLabels = ['Summary', 'Charts', 'Profile', 'Schedules', 'Files', 'Notes'];

  // schedule button added if patient has active careplan
  navLabels = ['Summary', 'Charts', 'Profile'];

  private unsubscribe$ = new Subject<void>();
  patientDetails: FhirPatientResponse | null = null;

  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.sidebar-visible') isSidebarVisible = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;
  @ViewChild('calButton', { static: false }) calButton!: ElementRef;

  patientId!: string;
  patientCarePlanName!: string;
  patientActiveCarePlan!: CarePlan;
  patientActiveCarePlan$ = new Subject<CarePlan>();
  dataCalLink!: string;

  constructor(
    private patientService: PatientService,
    private breakpointService: BreakpointService,
    private router: Router,
    private carePlanService: CarePlanService,
    private calcomService: CalcomService,
    private dialog: MatDialog,
    private showLoaderService: ShowLoaderService,
    private notificationService: NotificationService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    
    // get the patient id from the url paramerter 
    const id = this.router.url.split("/")[1];
    // #R2-1116 - handled route without status if the user navigate from dashboard & email alert notication due to #R2-849
    const status = this.router.url.split("/")[3] || 'active';
    this.patientId = id;
    /*
    generate links based on patient id for proper highlighting 
    and navigation of material tab navigation.
    */
    const links: Array<any> = [];

    // #R2-849 added status key for checking tab
    this.navLabels.forEach(label => {
      links.push({ location: `${id}/` + label.toLowerCase() +  `/${status}`, label });
    });
    this.navLinks = links;
    this.updatePatientDetails();
    this.carePlanService.patientCarePlans$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((carePlans: CarePlan[]) => {
        let [_, id, subTab, status] = this.router.url.split("/");
        // Handled active status as default
        if (!status) status = 'active';
        if (carePlans.findIndex(({subject: { reference }}) => reference && reference === `Patient/${id}`) === -1) return; 
        let activeCarePlan = carePlans.find(c => c.status === 'active');
        
        // #1R2-1147 - after discharge navlinks has to change
        this.navLinks = this.navLinks.map(nav => {
          return {
            ...nav,
            location: `${id}/` + nav.label.toLowerCase() + `/${status}`
          };
        });

        // show schedule link for patient with active careplan
        if (activeCarePlan) {
          this.navLinks = [
            ...links,
            { location: '', label: 'Schedule' }
          ];
          this.patientActiveCarePlan = activeCarePlan;
        }
        // User should redirect to inactive if there's no active careplan
        if (carePlans.length > 0 && !activeCarePlan && status === 'active') {
          this.router.navigate([`../${this.patientId}/${subTab}/inactive`], { replaceUrl: false });
          return;
        }
      });

    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.isTablet = value.Tablet;
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });

    /**
   * Listen sidebar toggle status
   */
    showSidebar$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((sidebarVisible: boolean) => {
        this.isSidebarVisible = sidebarVisible;
      });

    this.patientService.updatePatientInformation
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.updatePatientDetails();
      });
    
    /**
     * Logic for scheduling meeting:
     * planDefinition context is extracted from active carePlan inorder to fetch action payload in planDefinition
     * Calcom team id is extracted from action payload for fetching team events for the specific calcom team
     * If managed event exists in team events, calcom provider screen is shown
     * else round robin screen is shown in embed popup
     * 
     * Note: Schedule button only shown for patients with active careplan!
     * 
     */
    this.patientActiveCarePlan$
      .pipe(
        switchMap((activeCarePlan: CarePlan) => { 
          const patient = this.patientService.currentPatient.getValue();
          if (!patient) {
            this.showLoaderService.isShowLoadingSectionVisible$.next(false);
            return throwError(() => new Error('Patient resource not available'));
          }
          return of({
            patient,
            activeCarePlan
          });
        }),
        switchMap(({ patient, activeCarePlan }: { patient: FhirPatientResponse, activeCarePlan: CarePlan}) => {
          this.showLoaderService.isShowLoadingSectionVisible$.next(true);
          const planDefinitionString = activeCarePlan?.activity?.[0]?.detail?.instantiatesCanonical?.[0] ?? null;
          if (!planDefinitionString) {
            this.showLoaderService.isShowLoadingSectionVisible$.next(false);
            return throwError(() => new Error('PlanDefinition not available for scheduling meeting workflow'));
          }
          const planDefinitionIndex = planDefinitionString.split('/').length - 1;
          return combineLatest([
            of(patient),
            this.carePlanService.getPlanDefinitionById(planDefinitionString.split('/')[planDefinitionIndex]),
          ]);
        }),
        switchMap(([patient, planDefinition]: [FhirPatientResponse, PlanDefinition & ActionForProgram]) => { 
          const calcomTeamId = planDefinition?.action?.[0]?.code?.[0]?.coding?.[0]?.code || null;
          if (!calcomTeamId) {
            this.showLoaderService.isShowLoadingSectionVisible$.next(false);
            return throwError(() => new Error('Action payload/Calcom team context not available in PlanDefinition'));
          }
          return combineLatest([
            of(patient),
            this.calcomService.getCalcomTeamEvents(calcomTeamId),
          ]);
        }),
        switchMap(([patient, calcomEvents]: [FhirPatientResponse, Record<'events', CalcomEvent[]>]) => { 
          if (!calcomEvents.events?.length) {
            this.showLoaderService.isShowLoadingSectionVisible$.next(false);
            this.notificationService.showNotification('No events available to schedule meeting.', 'error', 'Ok');
            return EMPTY;
          }
          this.dataCalLink = '';
          const managedEvent = calcomEvents.events.filter((v: CalcomEvent) => v.event_type === CalcomEventTypes.MANAGED);
          
          // handle schedule meeting for managed event
          if (managedEvent.length) {
            this.dialog.open<SelectCalcomTeamMemberComponent, SelectCalcomTeamMemberModalData>
              (SelectCalcomTeamMemberComponent, {
                data: {
                  // members are provided in managed event
                  members: managedEvent[0].providerMembers!,
                  patient
                },
                ...DefaultDialogProperties,
                width: '500px',
              })
              .afterClosed()
              .subscribe();
            
            this.showLoaderService.isShowLoadingSectionVisible$.next(false);
            return of(null);
          }

          // handle schedule meeting for round robin
          const roundRobinEvent = calcomEvents.events.filter((v: CalcomEvent) => v.event_type === CalcomEventTypes.ROUND_ROBIN)
          if (!roundRobinEvent.length || !roundRobinEvent[0]?.link) { 
            this.showLoaderService.isShowLoadingSectionVisible$.next(false);
            return throwError(() => new Error('Round robin event/link not found'));
          }
          this.showLoaderService.isShowLoadingSectionVisible$.next(false);
          const { firstName, lastName, email } = patient;
          const path: string = roundRobinEvent[0].link.split('/').slice(-3).join('/');
          this.dataCalLink = `${path}?name=${firstName} ${lastName}&email=${email}`;
          this.changeDetectorRef.detectChanges();
          this.calButton.nativeElement.click();
          return EMPTY;
        }),
        catchError((error) => { 
          this.showLoaderService.isShowLoadingSectionVisible$.next(false);
          this.notificationService.showNotification('Scheduling meeting failed. Try again later.', 'error', 'Ok');
          return throwError(() => new Error(error.message));
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
    
    this.patientService.removeSchedulingForDischargedPatients$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.navLinks = this.navLinks.filter((v) => v.label !== 'Schedule');
      });
  }

  updatePatientDetails(): void {
    // get patient id from route and fetch patient details
    combineLatest([
      this.patientService.getPatientById(this.patientId),
      this.carePlanService.getPatientCarePlan(this.patientId),
    ])
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(([patient, patientCarePlan]) => {
      this.patientDetails = patient;
      this.patientService.currentPatient.next(patient);
      const carePlans = MapBundleToResourceArray<CarePlan>(patientCarePlan);
      // #R2-849 patient should have at least 1 active careplan or completed one
      // R2-1146: select active careplan else select latest completed careplan 
      // using careplan's period.start property since a patient could have multiple completed careplans
      const [activeCarePlan, completedCarePlans] = partition(carePlans, carePlan => carePlan.status === 'active');
      const activeOrCompletedCarePlan = activeCarePlan.length
        ? activeCarePlan[0]
        : orderBy(completedCarePlans, [carePlan => new Date(carePlan.period!.start!)], ['desc'])[0];
      
      // remove schedule button for patient with inactive careplans
      if (!activeCarePlan.length) {
        this.navLinks = this.navLinks.filter((v) => v.label !== 'Schedule');
      }

      if (!activeOrCompletedCarePlan) {
        throw new Error('No active careplan for selected patient');
      }
      this.carePlanService.profileSelectedCarePlan$.next(activeOrCompletedCarePlan);
      this.patientCarePlanName = activeOrCompletedCarePlan!.title!;
    });
  }

  public navLinkTrackBy(index: number, item: any) {
    return item.location
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * #R2-559 - need to call update patient details wheb tab switch to profile
   */
  onSwitchTab(navTab: string){
    if (navTab === 'Profile') this.updatePatientDetails();
    if (navTab === 'Schedule') this.patientActiveCarePlan$.next(this.patientActiveCarePlan);
  }
}
