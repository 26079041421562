import { sortBy } from 'lodash';
import { Bundle, BundleEntry, Goal, PlanDefinition } from 'fhir/r4';
import { Subject, takeUntil } from 'rxjs';

import { Component, HostBinding, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormControlName, FormGroup, FormGroupDirective, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { GoalDecimalInput, GoalsMinMaxValues } from 'src/app/config/app.config';
import { GoalCodes } from 'src/app/config/app.config';
import { MonitoringProgramDialogData } from 'src/app/models/matDialogData.model';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PatientService } from 'src/app/services/patient.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom-breakpoint-state-interface';
import { FhirPatientResponse } from 'src/app/shared/models/fhir/patient/response/fhir-patient-response';
import { rearrangeBloodPressureGoals } from 'src/app/utils/shared.util';
import { GoalService } from 'src/app/services/goal.service';

@Component({
  selector: 'patient-app-monitoring-program-dialog',
  templateUrl: './monitoring-program-dialog.component.html',
  styleUrls: ['./monitoring-program-dialog.component.scss']
})
export class MonitoringProgramDialogComponent implements OnInit, OnDestroy {
  @ViewChild('addedGoalsRef') addedGoalsRef!: MatSelect;
  displayedColumns = ['metricName', 'lowValue', 'highValue', 'action'];
  formGroup!: FormGroup;
  programs!: Array<PlanDefinition>;
  selectedProgram: any;
  dataSource = new MatTableDataSource<any>();
  // selected program's preset goals: rearranged to show blood pressure goals last
  presetGoals: any[] = [];
  // goals with on-hold status from FHIR API: rearranged to show blood pressure goals last
  addedGoals!: Array<Goal>;
  // add metrics dropdown's selected goals
  selectedGoals: any[] = [];
  isRenderForm = false;
  patientProgramEnrollmentTask: any;
  newPatientId!: string;
  isInactivePatient!: boolean;
  previousPresetGoalValues: Array<{ low: number, high: number } | null> = [];
  goalsMinMaxValues = GoalsMinMaxValues;
  bloodPressureCode = GoalCodes.BLOOD_PRESSURE;
  isBloodPressureSelected = false;

  // newly added patient from patient service addPatient
  newPatient!: FhirPatientResponse | null;
  unsubscribe$: Subject<void> = new Subject();

  /** Used for setting as xlarge screen for responsiveness */
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: MonitoringProgramDialogData,
    private dialogRef: MatDialogRef<MonitoringProgramDialogComponent>,
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private breakpointService: BreakpointService,
    private notificationService: NotificationService,
    private goalService: GoalService
  ) { }

  ngOnDestroy(): void {
    this.selectedGoals = [];
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.programs = this.dialogData.programs?.filter((program: PlanDefinition) => program.status !== 'draft');
    this.addedGoals = sortBy(this.dialogData.goals, rearrangeBloodPressureGoals);
    this.newPatientId = this.dialogData?.patientId ?? '';
    this.patientProgramEnrollmentTask = this.dialogData?.patientProgramEnrollmentTask;
    this.isInactivePatient = !!this.dialogData?.isInactivePatient;
    // for pending patients
    if (this.dialogData?.fhirPatientResponse) {
      this.newPatient = this.dialogData.fhirPatientResponse
    }

    this.selectedProgram = {
      ...this.programs[0],
      goal: sortBy(this.programs[0]?.goal ?? [], rearrangeBloodPressureGoals),
    };
    this.presetGoals = this.selectedProgram?.goal ?? [];
    this.isBloodPressureSelected = this.getBloodPressureSelection();

    this.patientService.addedPatient$.subscribe({
      next: (patientDetails: any) => {
        if (patientDetails) {
          this.newPatient = patientDetails;
        }
      }
    });
    this.buildDynamicModalForm();

    // if modal opened cause of API error, reload previous form values
    if (this.dialogData?.previousFormValue) {
      this.formGroup.patchValue({ ...this.dialogData.previousFormValue });
    }

    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
  }

  buildDynamicModalForm(): void {
    // Requirement: Check preset goals in added goals dropdown
    // Logic: Compare added goal's target code against all target codes in preset goals
    // target[0].measure.coding[0].code is a unique code for specific goal except blood pressure systolic/diastolic goals
    const presetGoalCodes = this.presetGoals.map(({ target }: any) => target[0].measure.coding[0].code);

    this.selectedGoals = this.addedGoals
      .map((goal: any) => presetGoalCodes.includes(goal.target[0].measure.coding[0].code) ? goal : null)
      .filter(Boolean);

    this.formGroup = this.formBuilder.group({
      tableForm: this.formBuilder.array([]),
    });
    this.formGroup = this.formBuilder.group({
      programName: [
        this.dialogData.disableProgramTitleDescription
          ? this.selectedProgram.title
          : this.selectedProgram.id
      ],
      programDescription: [this.selectedProgram.description, Validators.required],
      addMetrics: [this.selectedGoals],
    });
    this.updatePresetGoalsUI();
  }

  /**
   * Checks preset goals & returns true when any blood pressure goal is selected 
   * For toggling both systolic/diastolic blood pressure goals selection status together in add metrics dropdown
   */
  private getBloodPressureSelection(): boolean {
    return !!this.presetGoals.find((goal: Goal) => goal.target?.[0].measure?.coding?.[0].code === this.bloodPressureCode);
  }

  updatePresetGoalsUI(): void {
    this.previousPresetGoalValues.length = 0;
    this.formGroup.removeControl('tableForm');
    this.formGroup.addControl('tableForm', this.formBuilder.array(
      this.presetGoals?.length
        ? this.presetGoals
          .flatMap((goal: Goal) => {
            const { id: goalId, target = [], description: { text: metricName = '' } = {} } = goal;
            if (!goalId) {
              throw new Error('Goal id not accessible');
            }
            return target.map(({ detailRange = {}, measure = {} }: any) => {
              if (!measure?.coding || !measure?.coding?.length) {
                throw new Error('Goal\'s coding is not specified');
              }
              const { code: goalUniqueCode } = measure.coding[0];
              this.previousPresetGoalValues.push(null);
              return this.formBuilder.group({
                metricName: new FormControl(metricName),
                low: new FormControl(detailRange?.low?.value ?? '', [Validators.required, this.getGoalDecimalInput(goalUniqueCode)]),
                high: new FormControl(detailRange?.high?.value ?? '', [Validators.required, this.getGoalDecimalInput(goalUniqueCode)]),
                isEditable: new FormControl({ value: false, disabled: false }),
                code: new FormControl(detailRange?.low?.unit || detailRange?.high?.unit || ''),
                goalId: new FormControl(goalId),
                goalUniqueCode: new FormControl(goalUniqueCode),
              });
            })
          })
        : []
    ));
    this.dataSource = new MatTableDataSource((this.formGroup.get('tableForm') as FormArray).controls);
    this.isRenderForm = true;
  }

  // R2-1093
  private getGoalDecimalInput(goalUniqueCode: string): ValidatorFn {
    return GoalDecimalInput[goalUniqueCode]
      ? Validators.pattern(/^[0-9.]+$/)
      : Validators.pattern(/^[0-9]+$/);
  }

  getTableForm() {
    return this.formGroup.controls?.['tableForm'] as FormArray;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  closeDialogWithSave(): void {
    // #R2-522 - bug fix
    const error = this.validateAllMetricsThresholdValue();
    if (error) {
      this.notificationService.showNotification(error.message || "Invalid inputs", 'error', 'Ok');
      return;
    }
    this.dialogRef.close({
      patientId: this.newPatientId,
      patientProgramEnrollmentTask: this.patientProgramEnrollmentTask,
      formValue: this.formGroup.value,
      chosenProgram: this.selectedProgram,
      carePlan: this.dialogData?.carePlan,
      allGoals: [
        ...this.addedGoals,
        ...this.presetGoals,
      ],
    });
  }

  saveEditedGoals(): void {
    // #R2-522 - bug fix
    const error = this.validateAllMetricsThresholdValue();
    if (error) {
      this.notificationService.showNotification(error.message || "Invalid inputs", 'error', 'Ok');
      return;
    }
    this.dialogRef.close({
      formValue: this.formGroup.value,
      carePlan: this.dialogData?.carePlan,
      presetGoals: this.presetGoals,
    });
  }

  onProgramChanged(event: any): void {
    const programSelected = this.programs.find(({ id }) => id === event.value);
    if (!programSelected) {
      throw new Error('Program selection error');
    }
    this.selectedProgram = {
      ...programSelected,
      goal: sortBy(programSelected?.goal ?? [], rearrangeBloodPressureGoals),
    };
    this.presetGoals = this.selectedProgram?.goal ?? [];
    this.isBloodPressureSelected = this.getBloodPressureSelection();
    this.buildDynamicModalForm();
    // #R2-459 - need to retrive default goals & map selected program goals
    this.getGoals();
  }

  /* 
    * Get goals used to call for reset the default threshold on program change
  */
  getGoals(){
    this.goalService.getGoals()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((goals: Bundle<Goal>) => {
        const addedGoals = goals?.entry?.length ? goals.entry.map(({ resource }: BundleEntry<Goal>) => {
          const programGoal = this.selectedProgram.goal.find((goal: any) => goal.description.text === resource?.description?.text && goal.target?.[0].measure?.coding?.[0].code === resource?.target?.[0].measure?.coding?.[0].code);
          return programGoal ? programGoal : resource!
        }) : [];
        this.addedGoals = sortBy(addedGoals, rearrangeBloodPressureGoals);
        this.buildDynamicModalForm();
      });
  }

  onEditRow(formGroup: any, index: number): void {
    const { low, high } = formGroup.get('tableForm').at(index).value;
    this.previousPresetGoalValues.splice(index, 0, { low, high });
    formGroup.get('tableForm').at(index).get('isEditable').patchValue(true);
  }

  /**
   * A goal's min and max value is stored as config property in frontend 
   * since it's not available in goal's FHIR response with on-hold status
   */
  onSaveRow(formGroup: any, index: number): void {
    const { low, high } = formGroup.get('tableForm').at(index).value;
    // #R2-522 - bug fix
    const error = this.validateThresholdValue(formGroup.get('tableForm').at(index).value)
    if (error.hasError) {
      this.notificationService.showNotification(error.message || "Invalid inputs", 'error', 'Ok');
      return;
    }
    // #R2-459 - Coping values in temp variables in order to prevent loosing of default threshold values
    const presetGoal = this.presetGoals[index];
    const tempLowValue = presetGoal.target[0].detailRange.low.value;
    const tempHighValue = presetGoal.target[0].detailRange.high.value;

    
    this.presetGoals[index].target[0].detailRange.low.value = low;
    this.presetGoals[index].target[0].detailRange.high.value = high;

    /**
     * #R2-459 - reset the temp default threshold values
     */
    this.addedGoals = this.addedGoals.map(goal => {
      if (goal.description.text === presetGoal.description.text && goal.target?.[0].measure?.coding?.[0].code === presetGoal.target?.[0].measure?.coding?.[0].code) {
        const { target } = goal;
        goal.target = [
          {
            ...target?.[0],
            detailRange: {
              low: {
                ...target?.[0].detailRange?.low,
                value: tempLowValue,
              },
              high: {
                ...target?.[0].detailRange?.high,
                value: tempHighValue,
              }
            }
          }
        ];
      }
      return goal;
    });
    formGroup.get('tableForm').at(index).get('isEditable').patchValue(false);
  }

  /**
   * To validate all the threshold values in the tableForm
   */
  validateAllMetricsThresholdValue(): { message?: string, hasError: boolean } | undefined {
    return (this.formGroup.get('tableForm') as FormArray).controls.map(control => this.validateThresholdValue(control.value)).find(({hasError}) => hasError);
  }

   /**
   * To validate threshold values of specific metrics on update row in tableForm
   */
  validateThresholdValue({ low, high, goalUniqueCode, metricName } : { 
    low: string, high: string, goalUniqueCode: string, metricName: string
   }) : { message?: string, hasError: boolean } {
    const currentGoalMinMaxValues = this.goalsMinMaxValues[goalUniqueCode];
    if (!currentGoalMinMaxValues) {
      return {
        hasError: true,
        message: "Goal\'s min max values not available"
      };
    }
    const { low: currentGoalLow, high: currentGoalHigh } = currentGoalMinMaxValues;
    if (low < currentGoalLow) {
      return {
        hasError: true,
        message: `${metricName} low value is lower than min value of ${currentGoalLow}`
      };
    }
    if (high > currentGoalHigh) {
      return {
        hasError: true,
        message: `${metricName} high value is higher than max value of ${currentGoalHigh}`
      };
    }
    if (+high < +low) {
      return {
        hasError: true,
        message: `Low value can\'t be higher than high value for ${metricName}`
      };
    }
    return { hasError: false };
  }

  onCancelRow(formGroup: any, index: number): void {
    formGroup.get('tableForm').at(index).patchValue(this.previousPresetGoalValues[index]);
    formGroup.get('tableForm').at(index).get('isEditable').patchValue(false);
  }

  onGoalsAdded(): void {
    if (!this.selectedGoals.length) {
      this.addedGoalsRef.open();
      this.notificationService.showNotification('Please select at least one metric that needs to be monitored', 'error', '');
      return;
    };
    // filter out newly added goals from preset goals
    const presetGoalsCache: Record<string, Goal> = this.presetGoals.reduce((previous, current) => {
      const { target } = current;
      /*
        * #R2-209 - Fixed repeating blood pressure metrics
        * blood pressure has 2 metrics sys & dia but the code is same for both metrics,
        * so here combining with code with description text as object key for not to repeating
      */
      const uniqueGoalCode = target[0].measure.coding[0].code;
      const codeKey = uniqueGoalCode === this.bloodPressureCode ? `${uniqueGoalCode}-${current.description.text}` : uniqueGoalCode;
      previous[codeKey] = current;
      return previous;
    }, {});
    const presetGoalCodes = Object.keys(presetGoalsCache);
    this.presetGoals = this.selectedGoals.map(({ id, description, target, ...rest }) => {
      /*
        * #R2-209 - Fixed repeating blood pressure metrics
        * blood pressure has 2 metrics sys & dia but the code is same for both metrics,
        * so here combining with code with description text as object key for not to repeating
      */
      const uniqueGoalCode = target[0].measure.coding[0].code;
      const codeKey = uniqueGoalCode === this.bloodPressureCode ? `${uniqueGoalCode}-${description.text}` : uniqueGoalCode;
      if (presetGoalCodes.includes(codeKey)) {
        return presetGoalsCache[codeKey];
      }
      return {
        id,
        description,
        target,
        ...rest
      }
    });
    this.updatePresetGoalsUI();
  }

  findBloodPressure = (goal: Goal) => {
    return goal.target?.[0].measure?.coding?.[0].code === this.bloodPressureCode;
  }

  handleGoalClick(goal: Goal) {
    if (goal.target?.[0].measure?.coding?.[0].code === this.bloodPressureCode) {
      if (this.isBloodPressureSelected) {
        const updatedGoals = this.selectedGoals.filter((goal: Goal) => {
          return !this.findBloodPressure(goal);
        })
        this.selectedGoals = [
          ...updatedGoals
        ];
        this.isBloodPressureSelected = false;
        return;
      }

      const otherBloodPressure = this.addedGoals.find((existingGoal: Goal) => {
        return (
          existingGoal.target?.[0].measure?.coding?.[0].code === goal.target?.[0].measure?.coding?.[0].code
          &&
          existingGoal.description.text !== goal.description.text
        )
      });
      if (otherBloodPressure) {
        this.isBloodPressureSelected = true;
        this.selectedGoals = sortBy([ ...this.selectedGoals, otherBloodPressure ], rearrangeBloodPressureGoals);
      }
    }
  }

}
