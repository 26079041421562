import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarePlan, Task } from 'fhir/r4';
import { Observable, map } from 'rxjs';
import { DefaultResourceQueryCount } from '../config/app.config';
import { MapTaskResourceToAcknowledged } from '../mappers/task.mapper';
import { AcknowledgedTaskParams, AlertFilters } from '../models/task.model';
import { BaseService } from './base-api.service';
import { FilterAlertType } from '../models/powerbi.model';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends BaseService {

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getPatientTasks(patientId: string, careplan?: CarePlan, status: string = 'ready'): Observable<any> {
    ''
    // #R2-1115, R2-764 & R2-849 handled task resrc status for both active & inactive tab as it previously works only for inactive tab
    let queryParams = `?patient:Patient._id=${patientId}&code:text=Patient Observation Alert&status=${status}&_total=accurate&_count=${DefaultResourceQueryCount}&_sort=-_lastUpdated`;
    if (careplan?.period?.start) {
      queryParams = `${queryParams}&authored-on=ge${careplan?.period?.start}`;  // https://dxpbhc.atlassian.net/browse/R2-568
    }
    // #R2-898 filtering based on current plan id
    if (careplan?.id) {
      queryParams += `&based-on=CarePlan/${careplan.id}`
    }
    return this.http.get(`${this.FHIR_BASE}/Task${queryParams}`);
  }

  getCareplanTasks(careplanId: string): Observable<any> {
    return this.http.get(`${this.FHIR_BASE}/Task?based-on=${careplanId}&_sort=-_lastUpdated`);
  }

  updateTaskResourceToAcknowledged(acknowledgedTaskParams: AcknowledgedTaskParams): Observable<any> {
    const { taskId } = acknowledgedTaskParams;
    const taskPayload: Task = MapTaskResourceToAcknowledged(acknowledgedTaskParams);
    return this.http.put(`${this.FHIR_BASE}/Task/${taskId}`, taskPayload);
  }

  getFilteredPatientTasks(patientId: string, alertFilters: Partial<AlertFilters>, carePlanId: string): Observable<any> {
    const queryString: string = Object.entries(alertFilters)
      .map(([key, value]: [string, string]) => {
        if (!value || value === FilterAlertType.ALL) {
          return null;
        }
        return `&${key !== 'authoredOn' ? key : 'authored-on'}=${value}`;
      })
      .filter(Boolean)
      .join('');
    // #R2-898 filtering based on current plan id
    return this.http.get(`${this.FHIR_BASE}/Task?patient:Patient._id=${patientId}&code:text=Patient Observation Alert&_total=accurate&_count=${DefaultResourceQueryCount}&_sort=-_lastUpdated${queryString}&based-on=CarePlan/${carePlanId}`);
  }

  /**
   * Used to check the patient has not ackowledged task ot not
   */
  hasPatientNotAcknowledgedTask(patientId: string, careplan?: CarePlan): Observable<boolean> {
    let queryParams = `?patient:Patient._id=${patientId}&code:text=Patient Observation Alert&status=ready&_total=accurate&_count=1`;
    if (careplan?.period?.start) {
      queryParams = `${queryParams}&authored-on=ge${careplan?.period?.start}`;  // https://dxpbhc.atlassian.net/browse/R2-568
    }
    // #R2-898 filtering based on current plan id
    if (careplan?.id) {
      queryParams += `&based-on=CarePlan/${careplan.id}`
    }
    return this.http.get(`${this.FHIR_BASE}/Task${queryParams}`).pipe(map((response: any) => response.total > 0));
  }
}
