import '@zonejs-patch'; // Note: zonejs-patch import required for proper working of rxjs
import cloneDeep from 'lodash/cloneDeep';

import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Bundle, BundleEntry, CarePlan, Goal, Patient, PlanDefinition, Task } from 'fhir/r4';
import { Subject, switchMap, takeUntil, catchError, Observable, EMPTY, of, combineLatest, filter, forkJoin, throwError, retry, NEVER, tap, pipe, UnaryFunction } from 'rxjs';
import { ImportDataDialogComponent } from './components/import-data-dialog/import-data-dialog.component';
import { MonitoringProgramDialogComponent } from './components/monitoring-program-dialog/monitoring-program-dialog.component';
import { PatientInformationDialogComponent } from './components/patient-information-dialog/patient-information-dialog.component';
import { DefaultDialogProperties, DialogResponsiveWidth, FhirResourceType, spinnerProperties } from './config/app.config';
import { MapCarePlanToPlanDefinition } from './mappers/careplan.mapper';
import { Diagnosis } from './models/diagnosis.model';
import { ImportDataDialogData, MonitoringProgramDialogData, PatientInformationDialogData } from './models/matDialogData.model';
import { CarePlanService } from './services/careplan.service';
import { DiagnosisService } from './services/diagnosis.service';
import { GoalService } from './services/goal.service';
import { NotificationService } from './services/notification.service';
import { PatientService } from './services/patient.service';
import { ProgramService } from './services/program.service';
import { MonitoringProgramModalPayload, AddPatientModalFormValue, ExtendedPatient, ImportDataModalPayload } from './models/patient.model';
import { MapBundleToResourceArray, MapBundleToResourceArrays } from './mappers/shared.mapper';
import { GetPatientIdTaskId } from './mappers/patient.mapper';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, InteractionStatus, AuthenticationResult, EventType, SilentRequest, InteractionRequiredAuthError, AuthError } from '@azure/msal-browser';
import { FhirPatientResponse } from './shared/models/fhir/patient/response/fhir-patient-response';
import { activeRoute$, showSidebar$ } from '@bayshoreHealthCare/store';
import { BreakpointService } from './services/breakpoint.service';
import { CustomBreakpointState } from './shared/interfaces/custom-breakpoint-state-interface';
import { ShowLoaderService } from './services/show-loader.service';
import { InactivityService } from './services/inactivity.service';
import { onOrganizationChange$ } from '@bayshoreHealthCare/store';

@Component({
  selector: 'patient-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private addPatientInitiated$ = new Subject<AddPatientModalFormValue>();
  private monitorProgramInitiated$ = new Subject<MonitoringProgramModalPayload>();
  private addPatientModalOutput!: AddPatientModalFormValue;
  defaultSpinnerProperties = spinnerProperties;
  isShowLoadingSectionVisible: boolean = true;
  programs!: PlanDefinition[];
  // R2-894
  defaultPrograms!: PlanDefinition[];
  goals!: Goal[];
  diagnosis!: Diagnosis[];
  activePatient!: ExtendedPatient | Patient;
  carePlanGoalsPlanDefinitionsBundle: Bundle<CarePlan | Goal> | null = null;
  // organization context in the format ["orgId", "orgName", "role"]
  activeOrganization!: Array<string>;

  userProfile: any;
  isIframe = false;
  loginDisplay = false;

  @HostBinding('class.sidebar-visible') isSidebarVisible = false;
  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    private dialog: MatDialog,
    private patientService: PatientService,
    private programService: ProgramService,
    private goalService: GoalService,
    private diagnosisService: DiagnosisService,
    private carePlanService: CarePlanService,
    private notificationService: NotificationService,
    private breakpointService: BreakpointService,
    private router: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private showLoaderService: ShowLoaderService,
    private inactivityService: InactivityService
  ) { }

  ngOnInit() {
    /** Listen for organization switch from sidebar */
    onOrganizationChange$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (organization: Array<string>) => {
          this.activeOrganization = organization;
        }
      });
    
    this.showLoaderService.isShowLoadingSectionVisible$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe((isLoading: boolean) => this.isShowLoadingSectionVisible = isLoading);

    /**
     * Navigate to this app's root path (active patients) whenever
     * we trigger route from sidebar app
     */
    activeRoute$
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError((err: any) => {
          console.log('Patient App: RPM Store Active route error : ', err)
          return of(false);
        })
      )
      .subscribe(({ activePage: url, isFromSideBar }: any) => {
        // #R2-421 Fixed the changeActiveRoute bug, so it redirects only the navigation triggers from sidebar
        if (String(url).toLowerCase() === "patient" && isFromSideBar) {
          this.router.navigateByUrl('/active');
        }
      })

    /**
    * Listen for breakpoint changes
    */
    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.isTablet = value.Tablet;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });

    /**
     * Listen sidebar toggle status
     */
    showSidebar$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((sidebarVisible: boolean) => {
        this.isSidebarVisible = sidebarVisible;
      })


    this.patientService.getActivePatientListing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
    this.patientService.getPendingPatientListing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
    this.patientService.getInactivePatientListing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();


    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    if (this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      const account = accounts[0];
      this.authService.instance.setActiveAccount(accounts[0]);
      this.userProfile = account.idTokenClaims;
      this.authService.acquireTokenSilent({
        account: account,
      } as SilentRequest).subscribe()
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          console.log(
            `Patient App:  No account found:${this.authService.instance.getAllAccounts().length
            }`
          );
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event) => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    // when session expires, the data and api will fail, and
    // need the user to relogin. 
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result: EventMessage) => {
        const error = result.error as AuthError;
        if (error instanceof InteractionRequiredAuthError) {
          this.authService.loginRedirect();
        }
      });

    // Get programs for edit program modal
    this.programService.getPrograms()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((programs: Bundle<PlanDefinition>) => {
        this.programs = programs?.entry?.length
          ? programs.entry.map(({ resource }: BundleEntry<PlanDefinition>) => resource!)
          : [];
        
        // R2-894
        this.defaultPrograms = cloneDeep(this.programs);
      });

    // Get goals for edit program modal added metrics
    this.getGoals();

    // Subscribe to add patient button events in child components
    this.patientService.initiateAddPatient$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => this.addPatientInitiated$.next(this.addPatientModalOutput));

    // Listen to add patient event
    this.addPatientInitiated$
      .pipe(
        switchMap((addPatientModalInput: AddPatientModalFormValue) => this.openAddPatientModal$(addPatientModalInput)),
        // handle add patient API call
        switchMap((addPatientModalOutput: AddPatientModalFormValue) => {
          if (!addPatientModalOutput) {
            return EMPTY;
          }
          this.showLoaderService.isShowLoadingSectionVisible$.next(true);
          // add patient POST API
          return this.patientService.addPatient(addPatientModalOutput)
            .pipe(
              // update patient program enrollment task resource status from draft to ready for newly created patient
              switchMap((response: Bundle) => {
                const { patientId, taskId } = GetPatientIdTaskId(response);
                return combineLatest([
                  of(patientId),
                  this.patientService.updatePatientProgramEnrollmentTaskStatus(patientId, taskId)
                ]);
              }),
              // show enroll patient modal 
              switchMap(([patientId, patientProgramEnrollmentTask]) => {
                this.showLoaderService.isShowLoadingSectionVisible$.next(false);
                this.patientService.refreshPendingPatientListing$.next();
                this.notificationService.showNotification("Patient created successfully", "success", "Ok");
                this.monitorProgramInitiated$.next({
                  patientId,
                  patientProgramEnrollmentTask,
                  previousFormValue: null,
                });
                return EMPTY;
              }),
              // on patient creation error, reopen add patient modal with previously entered form data
              catchError(() => {
                this.showLoaderService.isShowLoadingSectionVisible$.next(false);
                this.addPatientModalOutput = addPatientModalOutput;
                this.notificationService.showNotification("Error occured when creating Patient", "error", "Ok");
                this.addPatientInitiated$.next(addPatientModalOutput);
                return EMPTY;
              }),
              takeUntil(this.unsubscribe$)
            );
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    // Listen to enroll patient to program events
    this.monitorProgramInitiated$
      .pipe(
        switchMap((enrollPatientModalInput: any) => this.openMonitoringProgramModalForNewPatient$(enrollPatientModalInput)),
        // handle enroll patient to program API
        switchMap((enrollPatientModalOutput: any) => {
          // #R2-459 - Calling get goals to reset the updated threshold in program popup
          this.getGoals();
          if (!enrollPatientModalOutput) {
            return EMPTY;
          }
          this.showLoaderService.isShowLoadingSectionVisible$.next(true);
          // enroll patient to program API
          return this.patientService.enrollPatientToProgram(enrollPatientModalOutput)
            .pipe(
              switchMap((bundle: Bundle<any>) => {
                const { patientId, patientProgramEnrollmentTask, chosenProgram } = enrollPatientModalOutput;
                const { resource1: carePlans } = MapBundleToResourceArrays<CarePlan, any>(bundle, FhirResourceType.CarePlan);
                const carePlan = carePlans.filter((plan: CarePlan) => plan.title === chosenProgram.title)[0];
                return forkJoin([
                  this.patientService.getPatientById(patientId),
                  of(carePlan),
                  of(patientProgramEnrollmentTask),
                ])
              }),
              // create sidecar and validic users on successful enrollment
              switchMap((
                [fhirPatientResponse, carePlan, patientProgramEnrollmentTask]: [FhirPatientResponse, CarePlan, Task]
              ) => {
                return forkJoin([
                  of(fhirPatientResponse),
                  of(carePlan),
                  of(patientProgramEnrollmentTask)
                ]);
              }),
              // show import data modal
              switchMap((
                [fhirPatientResponse, carePlan, patientProgramEnrollmentTask]: [FhirPatientResponse, CarePlan, Task]
              ) => {
                this.showLoaderService.isShowLoadingSectionVisible$.next(false);
                this.notificationService.showNotification("Patient enrolled to Program", "success", "Ok");
                // pass patient program enrollment task & associated carePlan along with retrieved patient to import data dialog
                this.patientService.importDataInitiated$.next({
                  fhirPatientResponse,
                  patientProgramEnrollmentTask,
                  carePlan
                });
                this.patientService.refreshActivePatientListing$.next();
                this.patientService.refreshPendingPatientListing$.next();
                this.patientService.refreshInactivePatientListing$.next();
                return EMPTY;
              }),
              // on error while enrolling a patient to program, reopen enroll patient modal with previously entered form data
              catchError(() => {
                this.showLoaderService.isShowLoadingSectionVisible$.next(false);
                this.notificationService.showNotification("Error while enrolling Patient to Program", "error", "Ok");
                const { patientId, patientProgramEnrollmentTask } = enrollPatientModalOutput;
                this.monitorProgramInitiated$.next({
                  patientId,
                  patientProgramEnrollmentTask,
                  previousFormValue: enrollPatientModalOutput.formValue,
                });
                return EMPTY;
              }),
            )
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    // Listen to import data
    this.patientService.importDataInitiated$
      .pipe(
        switchMap((importDataDialogData) => {
          if (!importDataDialogData) {
            //to avoid import data dialog popup on app first render
            return of(false)
          }
          return this.openImportDialogData$(importDataDialogData)
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();

    // Get diagnosis data
    this.diagnosisService.getDefaultDiagnosis()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((diagnosis: Diagnosis[]) => this.diagnosis = diagnosis);

    // Subscribe to edit careplan for inactive patient
    // Requirement: Load all available programs and continue to import data modal
    this.carePlanService.editCarePlanForInactivePatient$
      .pipe(
        this.editCarePlanForInactivePatientPipe()
      )
      .subscribe();

    /**
     * Subscribe to edit careplan for active patients.
     * Requirement: Check if carePlan exists for current patient
     * if carePlan exists, load carePlan + goals
     * else load planDefinitions with active status.
     * Note: Instead of getting carePlan then goal data in 2 separate API calls
     * bundled GET request in getPatientCarePlanGoalsPlanDefinitions() 
     * will retrieve carePlan and related goals & planDefinitions in a single API call
     */
    this.carePlanService.editCarePlanForActivePatient$
      .pipe(
        this.editCarePlanForActivePatientPipe()
      )
      .subscribe();



    /**
     * Subscribe to edit careplan for pending patients.
     * Requirement: Check if patient has been enrolled into a program
     * and/or has completed device sync process.
     * Based on patient program enrollment task's status
     * for "ready" - show monitoring program modal
     * for "in-progress" - show import data modal
     */
    this.carePlanService.editCarePlanForPendingPatient$
      .pipe(
        this.editPendingPatientCarePlanPipe()
      )
      .subscribe();

    // To offset msal's delay in writing credentials to localStorage
    setTimeout(() => {
      const activeAccount = this.authService.instance.getActiveAccount();
      if (!activeAccount) {
        window.location.replace('/auth');
      }
    }, 2000);

    this.inactivityService.startInactivityTimer();
  }

  /* 
    * Get goals used to call at onInit and after close or save close MoniterProgramDialogComponent
  */
  getGoals() {
    this.goalService.getGoals()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((goals: Bundle<Goal>) => {
        this.goals = goals?.entry?.length ? goals.entry.map(({ resource }: BundleEntry<Goal>) => resource!) : [];
      });
  }

  /**
   * Edit active patient careplan functionality pipe
   * @returns pipe
   */
  private editCarePlanForActivePatientPipe = (): any => {
    return pipe(
      // get patient's carePlans & planDefinitions
      switchMap((patient: ExtendedPatient) => {
        const { id: patientId } = patient;
        if (!patientId) {
          throw new Error('Patient id not available');
        }
        this.activePatient = patient;
        // incase of error while saving patient goals, avoid calling the GET API again
        if (!this.carePlanGoalsPlanDefinitionsBundle) {
          return this.carePlanService.getPatientCarePlanGoals(patientId);
        }
        return of(this.carePlanGoalsPlanDefinitionsBundle);
      }),
      // open edit carePlan modal
      switchMap((bundle: Bundle<CarePlan | Goal>) => {
        this.carePlanGoalsPlanDefinitionsBundle = bundle;
        return this.openMonitoringProgramModalForActivePatient$(bundle);
      }),
      // handle edit carePlan POST API
      switchMap((editCarePlanModalOutput: { formValue: any, presetGoals: Goal[], carePlan: CarePlan }) => {
        // #R2-459 - Calling get goals to reset the updated threshold in program popup
        this.getGoals();
        if (!editCarePlanModalOutput) {
          this.carePlanGoalsPlanDefinitionsBundle = null;
          return EMPTY;
        }
        this.showLoaderService.isShowLoadingSectionVisible$.next(true);
        // todo: Provide the careplan corresponding to the selected patient;
        return this.carePlanService.updateActivePatientCarePlan(editCarePlanModalOutput);
      }),
      catchError(() => {
        this.showLoaderService.isShowLoadingSectionVisible$.next(false);
        this.notificationService.showNotification('Error while updating Patient Program', 'error', 'Ok');
        this.carePlanService.editCarePlanForActivePatient$.next(this.activePatient as ExtendedPatient);
        return this.carePlanService.editCarePlanForActivePatient$.pipe(
          this.editCarePlanForActivePatientPipe()
        );
      }),
      switchMap(() => {
        // #R2-458 - bug fix
        this.carePlanGoalsPlanDefinitionsBundle = null;
        this.showLoaderService.isShowLoadingSectionVisible$.next(false);
        this.notificationService.showNotification('Program goals updated for Patient', 'success', 'Ok');
        return EMPTY;
      }),
      takeUntil(this.unsubscribe$),
    )
  }

  /**
   * Edit Pending Patient Careplan functionality
   * @returns pipe
   */
  private editPendingPatientCarePlanPipe = (): any => {
    return pipe(
      // get patient's carePlans & planDefinitions
      switchMap((patient: ExtendedPatient) => {
        const { id: patientId } = patient;
        if (!patientId) {
          throw new Error('Patient id not available');
        }
        this.activePatient = patient;
        this.showLoaderService.isShowLoadingSectionVisible$.next(true);
        return forkJoin([
          this.patientService.getPatientTask(patientId),
          this.carePlanService.getPatientCarePlan(patientId),
          of(patientId)
        ]);
      }),
      // based on Task status show monitoring program modal (task === 'ready') or import data modal (task === 'in-progress')
      switchMap((
        [patientTaskBundle, carePlanBundle, patientId]: [Bundle<Patient | Task>, Bundle<CarePlan>, string]
      ) => {
        const { resource1: [patient], resource2: patientTaskList } =
          MapBundleToResourceArrays<Patient, Task>(patientTaskBundle, FhirResourceType.Patient, FhirResourceType.Task);
        const fhirPatientResponse = FhirPatientResponse.makeModel(patient);
        console.log("Fhir Response:"+JSON.stringify(fhirPatientResponse));
        this.showLoaderService.isShowLoadingSectionVisible$.next(false);
        // #R2-468 - referral patient task status should be matched with 'requested'.
        let patientProgramEnrollmentTask = patientTaskList.find((task: Task) => task.status === "ready" || task.status === "requested");
        if (patientProgramEnrollmentTask) {
          this.monitorProgramInitiated$.next({
            patientId,
            patientProgramEnrollmentTask,
            previousFormValue: null,
            fhirPatientResponse
          });
          return EMPTY;
        }
        const inprogressTask = patientTaskList.find((task: Task) => task.status === "in-progress");
        if (!inprogressTask) {
          throw new Error('Enrollment task does not exists for this patient.');
        }
        const carePlans = MapBundleToResourceArray(carePlanBundle);
        const carePlan = carePlans.find((plan: CarePlan) => plan.status === "draft");
        if (!carePlan) {
          // throwError(() => Error('Please enroll patient to a program.'));
          throw new Error('Please enroll patient to a program.');
        }
        // assumption: task status === 'in-progress'
        this.patientService.importDataInitiated$.next({
          patientProgramEnrollmentTask: inprogressTask,
          fhirPatientResponse,
          carePlan,
        });
        return EMPTY;
      }),
      takeUntil(this.unsubscribe$),
      catchError((error) => {
        this.notificationService.showNotification(error.toString(), 'error', 'Ok');
        this.carePlanService.editCarePlanForPendingPatient$.next(this.activePatient as ExtendedPatient);
        this.showLoaderService.isShowLoadingSectionVisible$.next(false);
        return this.carePlanService.editCarePlanForPendingPatient$.pipe(
          this.editPendingPatientCarePlanPipe()
        );
      }),
    )
  }

  private editCarePlanForInactivePatientPipe = (): any => {
    return pipe(
      switchMap((patient: Patient) => {
        const { id: patientId } = patient;
        if (!patientId) {
          throw new Error('Patient id not available');
        }
        this.activePatient = patient;
        return this.openMonitoringProgramModalForInactivePatient$({ patientId });
      }),
      // handle enroll inactive patient to program API
      switchMap((monitorProgramModalOutput) => {
        // #R2-459 - Calling get goals to reset the updated threshold in program popup
        this.getGoals();
        if (!monitorProgramModalOutput) {
          return EMPTY;
        }
        this.showLoaderService.isShowLoadingSectionVisible$.next(true);
        // enroll inactive patient to program
        return forkJoin([
          this.patientService.getPatientById(monitorProgramModalOutput.patientId),
          this.patientService.enrollPatientToProgram({
            ...monitorProgramModalOutput,
            isInactivePatient: true,
          }),
          of(monitorProgramModalOutput)
        ])
      }),
      switchMap(([
        fhirPatientResponse,
        enrollResponse, monitorProgramModalOutput]) => {
        const { resource1: taskList, resource2: carePlans } =
          MapBundleToResourceArrays<Task, CarePlan>(enrollResponse, FhirResourceType.Task, FhirResourceType.CarePlan);
        const inprogressTask = taskList.find((task: Task) => task.status === "in-progress");
        if (!inprogressTask) {
          throw new Error('Patient does not have an ongoing enrollment task');
        }
        this.showLoaderService.isShowLoadingSectionVisible$.next(false);
        this.notificationService.showNotification("Patient enrolled to Program", "success", "Ok");
        this.patientService.refreshActivePatientListing$.next();
        this.patientService.refreshPendingPatientListing$.next();
        this.patientService.refreshInactivePatientListing$.next();
        this.patientService.importDataInitiated$.next({
          patientProgramEnrollmentTask: inprogressTask,
          fhirPatientResponse: fhirPatientResponse,
          carePlan: carePlans[0],
        });
        return EMPTY;
      }),
      // on inactive patient to program API fail, re-open montoring program modal with previous form data
      catchError(() => {
        this.showLoaderService.isShowLoadingSectionVisible$.next(false);
        this.notificationService.showNotification("Error while enrolling Patient to Program", "error", "Ok");
        this.carePlanService.editCarePlanForInactivePatient$.next(this.activePatient);
        return this.carePlanService.editCarePlanForInactivePatient$
          .pipe(this.editCarePlanForInactivePatientPipe());
      }),
      takeUntil(this.unsubscribe$),
    )
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private openAddPatientModal$(previousFormValue?: AddPatientModalFormValue): Observable<any> {
    let dialogWidth = DialogResponsiveWidth.large;

    if (this.isTablet) dialogWidth = DialogResponsiveWidth.tablet;
    if (this.xlarge) dialogWidth = DialogResponsiveWidth.xlarge;
    if (this.retina) dialogWidth = DialogResponsiveWidth.retina;
    if (this.retinaXdr) dialogWidth = DialogResponsiveWidth.retinaXdr;
    if (this.retina4k) dialogWidth = DialogResponsiveWidth.retina4k;

    return this.dialog.open<PatientInformationDialogComponent, PatientInformationDialogData>(
      PatientInformationDialogComponent, {
      ...DefaultDialogProperties,
      width: dialogWidth,
      data: {
        diagnosis: this.diagnosis,
        ...(previousFormValue && { previousFormValue }),
      },
      id: 'patient-information-dialog'
    })
      .afterClosed();
  }

  /**
   * Load all carePlans and goals for new patient
   * @param param0 
   * @returns 
   */
  private openMonitoringProgramModalForNewPatient$(
    {
      patientId,
      patientProgramEnrollmentTask,
      previousFormValue = null,
      fhirPatientResponse,
    }: MonitoringProgramModalPayload
  ): Observable<any> {
    // add carePlan for newly created patient
    return this.openMonitoringProgramModal$({
      // R2-894
      programs: cloneDeep(this.defaultPrograms),
      goals: this.goals,
      patientProgramEnrollmentTask,
      patientId,
      ...(previousFormValue && { previousFormValue }),
      ...(fhirPatientResponse && { fhirPatientResponse }),
    });
  }

  private openImportDialogData$({ fhirPatientResponse, patientProgramEnrollmentTask, carePlan }: ImportDataModalPayload): Observable<any> {
    let dialogWidth = DialogResponsiveWidth.large;

    if (this.isTablet) dialogWidth = DialogResponsiveWidth.tablet;
    if (this.xlarge) dialogWidth = DialogResponsiveWidth.xlarge;
    if (this.retina) dialogWidth = DialogResponsiveWidth.retina;
    if (this.retinaXdr) dialogWidth = DialogResponsiveWidth.retinaXdr;
    if (this.retina4k) dialogWidth = DialogResponsiveWidth.retina4k;
    this.showLoaderService.isShowLoadingSectionVisible$.next(false);
    return this.dialog.open<ImportDataDialogComponent, ImportDataDialogData>(
      ImportDataDialogComponent, {
      ...DefaultDialogProperties,
      width: dialogWidth,
      data: {
        // for pending patients
        ...(!!fhirPatientResponse && { fhirPatientResponse }),
        ...(!!patientProgramEnrollmentTask && { patientProgramEnrollmentTask }),
        ...(!!carePlan && { carePlan }),
        activeOrganizationId: this.activeOrganization[0]
      }
    })
      .afterClosed();
  }

  /**
   * Load carePlan of active patient
   * Assumption: patients listed in the active tab will have 1 carePlan
   * @param bundle 
   */
  private openMonitoringProgramModalForActivePatient$(bundle: Bundle<CarePlan | Goal>): Observable<any> {
    // Assumption: patientCarePlan should be an array with length 1 since for phase 1, a patient will have only 1 carePlan
    const { resource1: patientCarePlan, resource2: patientGoals } = MapBundleToResourceArrays<CarePlan, Goal>(bundle, FhirResourceType.CarePlan, FhirResourceType.Goal);

    // edit carePlan for active patient
    return this.openMonitoringProgramModal$({
      programs: MapCarePlanToPlanDefinition(patientCarePlan[0], patientGoals),
      // added goals
      //#R2-368 - Patient Active tab "Add Metrics" dropdown field should be enabled
      goals: this.goals,
      disableProgramTitleDescription: true,
      //#R2-368 - Patient Active tab "Add Metrics" dropdown field should be enabled
      disableAddedGoals: false,
      editProgram: true,
      carePlan: patientCarePlan[0]
    });
  }

  /**
   * Load all carePlans and goals for inactive patient
   * @returns 
   */
  private openMonitoringProgramModalForInactivePatient$(
    { patientId }: MonitoringProgramModalPayload
  ): Observable<any> {
    return this.openMonitoringProgramModal$({
      programs: this.programs,
      goals: this.goals,
      editProgram: false,
      disableAddedGoals: false,
      isInactivePatient: true,
      patientId,
    });
  }

  private openMonitoringProgramModal$(initialModalData: MonitoringProgramDialogData): Observable<any> {
    let dialogWidth = DialogResponsiveWidth.large;

    if (this.isTablet) dialogWidth = DialogResponsiveWidth.tablet;
    if (this.xlarge) dialogWidth = DialogResponsiveWidth.xlarge;
    if (this.retina) dialogWidth = DialogResponsiveWidth.retina;
    if (this.retinaXdr) dialogWidth = DialogResponsiveWidth.retinaXdr;
    if (this.retina4k) dialogWidth = DialogResponsiveWidth.retina4k;
    this.showLoaderService.isShowLoadingSectionVisible$.next(false);
    return this.dialog.open<MonitoringProgramDialogComponent, MonitoringProgramDialogData>(
      MonitoringProgramDialogComponent,
      {
        ...DefaultDialogProperties,
        width: dialogWidth,
        data: initialModalData
      }
    )
    .afterClosed();
  }
}
